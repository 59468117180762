<div class="parent d-flex flex-column p-2 container">
  <div class="d-flex justify-content-center mb-5 pb-2 pt-5">
    <img *ngIf="!commonService.isWhitelabelled" src="/assets/img/fugu-logo-full.svg">
    <img class="h-45" *ngIf="commonService.isWhitelabelled" [src]="commonApiService.whitelabelConfigurations['logo']">
  </div>
  <div class="card p-4 justify-content-center flex-grow mb-5 mb-lg-4">
    <div class="card-content row no-gutters">
      <div class="col-12 col-md-5 pl-3 pr-5 pt-5 pb-5 logo-border d-none d-sm-none d-md-flex">
        <img src="/assets/img/forgot-password.svg" class="img-fluid" alt="forgot">
      </div>
      <div class="col-12 col-md-7 pl-md-5 pl-1 pb-5">
        <div class="pl-2 pr-4 pt-3">
          <p class="pt-4 mt-3 fugu-welcome-header mb-1">Password Reset</p>
          <p class="create-workspace-text mb-1">To reset your password, enter the {{signupText}}
            you use to sign in to your space
          </p>
          <form class="mt-2" role="form" [formGroup]="forgotPasswordForm" (submit)="getResetLink()">
            <div class="form-group mb-0 mr-5">
              <label class="form-custom-label pl-3 pt-2 mb-0">
                {{signupText}}
              </label>
              <app-intl-phone-input *ngIf="!isEmail && forgotPasswordForm.value.emailOrPhone" class="phone-dropdown-container position-absolute"
                                    [(phoneObj)]="selected_country_code"></app-intl-phone-input>
              <input type="text"
                     appAutofocus
                     [ngClass]="{'error' : commonService.getErrorMessage(forgotPasswordForm.controls.emailOrPhone),
                     'padding-phone-picker-input': !isEmail && forgotPasswordForm.value.emailOrPhone}"
                     [placeholder]="'Enter Your ' + signupText"  [maxLength]="!isEmail ? 12 : 200"
                     class="form-control form-signup-custom pl-3 mr-2"
                     formControlName="emailOrPhone"
                     id="inputEmail">
            </div>
            <div class="pl-3 validator-height">
              <app-validator [control]="forgotPasswordForm.controls.emailOrPhone"></app-validator>
            </div>
            <div class="form-custom-btn mt-4">
              <button class="btn btn-next btn-40 d-flex align-items-center pl-4 pr-4" [disabled]="!forgotPasswordForm.valid">Get Reset Link<img src="/assets/img/arrow-right.svg" alt="arrow-right" class="pl-2"></button>
            </div>
            <div class="mt-2 already-sign-in pl-1 pb-3">
              Back to <a class="app-color cursor-hand" [routerLink]="['/login']">Login</a>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
