// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

// FUGU_CONFERENCE_URL: 'https://conference.officechat.io',

export const environment = {
  production: false,
  beta: false,
  FUGU_CONFERENCE_URL: 'https://meet-test.fugu.chat',
  FUGU_API_ENDPOINT: 'https://beta-api-oc.fuguchat.com:3011/api/',
  SOCKET_ENDPOINT: 'https://beta-api.fuguchat.com:3011',
  ANALYTICS_ENDPOINT: 'https://analytics.officechat.io/login',
  REDIRECT_PATH: window.location.host,
  BRANCH_KEY: 'key_test_fnqO3lRG5jDzzlf06qCzsoamzDkWpv1d',
  LOGOUT_REDIRECT: window.location.origin + '/signup',
  INVITE_REDIRECT: window.location.origin + '/spaces',
  LOCAL_SPACE: 'funk3',
  // LOCAL_DOMAIN: 'blaze.chat',
  // LOCAL_DOMAIN: 'officechat.io',
  // LOCAL_DOMAIN: 'sabroson.com.mx',
  LOCAL_DOMAIN: 'officechat.io',
  STRIPE_KEY: 'pk_test_lBHsebfROM5GuExOJ7mmt9xb',
  CHROME_EXTENSION_ID: 'onnpnbcklmahhoajlilopflaandmlooa',
  ATTENDANCE_ANALYTICS: 'https://analytics-attendance.katoai.co/login'
};

// export const environment = {
//   production: true,
//   beta: true,
//   FUGU_CONFERENCE_URL: 'https://meet.fugu.chat',
//   FUGU_API_ENDPOINT: 'https://beta-api-oc.fuguchat.com:3013/api/',
//   SOCKET_ENDPOINT: 'https://beta-api.fuguchat.com:3013',
//   ANALYTICS_ENDPOINT: 'https://analytics.fuguchat.com/login',
//   REDIRECT_PATH: 'beta-app.fugu.chat',
//   BRANCH_KEY: "key_live_ifAO0lHL0ayruam79tyysddoCxm8kzO6",
//   EXTERNAL_REDIRECT: 'http://jwork.staging.wpengine.com/fuguchat/',
//   LOGOUT_REDIRECT: 'https://beta-app.fugu.chat/signup',
//   INVITE_REDIRECT: 'https://beta-app.fugu.chat/spaces',
//   LOCAL_SPACE: 'jungleworks',
//   LOCAL_DOMAIN: 'fugu.chat',
//   STRIPE_KEY: 'pk_live_M6q7uz3eOwakdRusewXXSvS1',
//   CHROME_EXTENSION_ID: 'onnpnbcklmahhoajlilopflaandmlooa',
//   ATTENDANCE_ANALYTICS: 'https://analytics-attendance.katoai.co/login'
// };
//


// For running live on local

// export const environment = {
//   production: false,
//   beta: false,
//   FUGU_CONFERENCE_URL: 'https://meet.fugu.chat',
//   FUGU_API_ENDPOINT: 'https://api.fugu.chat/api/',
//   SOCKET_ENDPOINT: 'https://socket.fugu.chat',
//   ANALYTICS_ENDPOINT: 'https://analytics.fuguchat.com/login',
//   REDIRECT_PATH: '.officechat.io',
//   BRANCH_KEY: 'key_test_fnqO3lRG5jDzzlf06qCzsoamzDkWpv1d',
//   EXTERNAL_REDIRECT: 'http://jwork.staging.wpengine.com/fuguchat/',
//   LOGOUT_REDIRECT: 'https://officechat.io/login',
//   INVITE_REDIRECT: 'https://app.fugu.chat/spaces',
//   LOCAL_SPACE: 'jungleworks',
//   LOCAL_DOMAIN: 'fugu.chat',
//   STRIPE_KEY: 'pk_live_M6q7uz3eOwakdRusewXXSvS1',
//   CHROME_EXTENSION_ID: 'onnpnbcklmahhoajlilopflaandmlooa',
//   ATTENDANCE_ANALYTICS: 'https://analytics-attendance.katoai.co/login'
// };
