import {Injectable} from '@angular/core';
import {ApiService} from '../../services/api.service';
import {CommonService} from '../../services/common.service';

@Injectable()
export class LoginViaUmsService {
  constructor(private api: ApiService, public commonService: CommonService) {
  }

  userLoginNewUms(data) {
    const obj = {
      'url': 'lpu/loginViaUMS',
      'type': 10,
      'body': data
    };
    return this.api.postOc(obj);
  }

  login(data) {
    data.time_zone = this.commonService.getTimeZone();
    const obj = {
      'url': 'user/v1/userLogin',
      'type': 2,
      'body': data
    };
    return this.api.postOc(obj);
  }

}
