import { Injectable } from '@angular/core';
import {Router, CanActivate} from '@angular/router';


@Injectable()
export class SignupGuardService implements CanActivate {

  constructor(private router: Router) { }

  canActivate() {
    return this.checkLogin();
  }

  checkLogin() {
   if (
     window.location.hostname == "lpulive.lpu.in" ||
     window.location.hostname == "chat.tsblive.com" ||
     window.location.hostname == "chat.deftalkng.com"
    //  || window.location.hostname == "chat.virtroom.ng"
   ) {
     if (
       ["/spaces", "/signup/4"].includes(window.location.pathname) &&
       (window.location.hostname == "chat.tsblive.com" ||
         window.location.hostname == "chat.deftalkng.com"
        // ||  window.location.hostname == "chat.virtroom.ng")
     )) {
       return true;
     } else {
       this.router.navigate(["/login"]);
     }
     return false;
   } else {
     return true;
   }
  }
}
