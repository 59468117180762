<div class="permissions-modal d-flex justify-content-center align-items-center h-100 w-100">
  <div class="permissions-modal__body">
    <div class="d-flex" [@popup-open]>
      <div class="arrow-image d-flex justify-content-center align-items-center">
        <img src="assets/img/arrow-right.svg" class="back-icon"/>
      </div>
      <div class="permissions-text">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
